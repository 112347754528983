import React, { useState, useEffect } from "react";
import { supabase } from "../../utils/supabaseClient"; // Adjust the import path based on your folder structure

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    currentCountry: "",
    currentState: "",
    currentCity: "",
    dob: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Basic form validation
  const validate = () => {
    const newErrors = {};
    if (!formData.fullname) newErrors.fullname = "Full name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.currentCountry) newErrors.currentCountry = "Country is required.";
    if (!formData.currentState) newErrors.currentState = "State is required.";
    if (!formData.currentCity) newErrors.currentCity = "City is required.";
    if (!formData.dob) newErrors.dob = "Date of birth is required.";

    return newErrors;
  };

  // Fetch current user profile from Supabase
  useEffect(() => {
    const fetchProfile = async () => {
      // Fetch the authenticated user
      const { data: { user }, error: userError } = await supabase.auth.getUser();

      if (userError) {
        console.error("Error fetching user:", userError);
        return;
      }

      // Check if the user ID is available
      if (!user || !user.id) {
        console.error("User is not authenticated or ID is undefined.");
        return;
      }

      // Fetch the profile using the user's ID
      const { data: profile, error: profileError } = await supabase
        .from("profile")
        .select("*")
        .eq("id", user.id) // Use user.id to fetch the profile
        .single();

      if (profileError) {
        console.error("Error fetching profile:", profileError);
      } else if (profile) {
        setFormData({
          fullname: profile.full_name || "",
          email: profile.email || "",
          currentCountry: profile.currentCountry || "",
          currentState: profile.currentState || "",
          currentCity: profile.currentCity || "",
          dob: profile.dob || "",
        });
      }
    };

    fetchProfile();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setLoading(true);
      try {
        const { data: { user }, error: userError } = await supabase.auth.getUser();

        if (userError || !user) {
          console.error("Error fetching user:", userError);
          return;
        }

        const { error } = await supabase
          .from("profile")
          .upsert({
            id: user.id, // Use the user ID for upsert
            full_name: formData.fullname,
            email: formData.email,
            currentCountry: formData.currentCountry,
            currentState: formData.currentState,
            currentCity: formData.currentCity,
            dob: formData.dob,
          });

        if (error) {
          console.error("Error updating profile:", error);
        } else {
          console.log("Profile updated successfully!");
        }
      } catch (err) {
        console.error("Error submitting form:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4 p-6 bg-white rounded-lg shadow-2xl w-[60rem]">
        <h2 className="text-2xl font-semibold text-center">Profile Information</h2>

        <div>
          <label htmlFor="fullname" className="block mb-1 font-medium">Full Name</label>
          <input
            type="text"
            id="fullname"
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            className={`border rounded-lg w-full p-2 ${errors.fullname ? "border-red-500" : "border-gray-300"}`}
            placeholder="Enter your full name"
          />
          {errors.fullname && <p className="text-red-500 text-sm mt-1">{errors.fullname}</p>}
        </div>

        <div>
          <label htmlFor="email" className="block mb-1 font-medium">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`border rounded-lg w-full p-2 ${errors.email ? "border-red-500" : "border-gray-300"}`}
            placeholder="Enter your email"
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        <div>
          <label htmlFor="currentCountry" className="block mb-1 font-medium">Current Country</label>
          <input
            type="text"
            id="currentCountry"
            name="currentCountry"
            value={formData.currentCountry}
            onChange={handleChange}
            className={`border rounded-lg w-full p-2 ${errors.currentCountry ? "border-red-500" : "border-gray-300"}`}
            placeholder="Enter your current country"
          />
          {errors.currentCountry && <p className="text-red-500 text-sm mt-1">{errors.currentCountry}</p>}
        </div>

        <div>
          <label htmlFor="currentState" className="block mb-1 font-medium">Current State</label>
          <input
            type="text"
            id="currentState"
            name="currentState"
            value={formData.currentState}
            onChange={handleChange}
            className={`border rounded-lg w-full p-2 ${errors.currentState ? "border-red-500" : "border-gray-300"}`}
            placeholder="Enter your current state"
          />
          {errors.currentState && <p className="text-red-500 text-sm mt-1">{errors.currentState}</p>}
        </div>

        <div>
          <label htmlFor="currentCity" className="block mb-1 font-medium">Current City</label>
          <input
            type="text"
            id="currentCity"
            name="currentCity"
            value={formData.currentCity}
            onChange={handleChange}
            className={`border rounded-lg w-full p-2 ${errors.currentCity ? "border-red-500" : "border-gray-300"}`}
            placeholder="Enter your current city"
          />
          {errors.currentCity && <p className="text-red-500 text-sm mt-1">{errors.currentCity}</p>}
        </div>

        <div>
          <label htmlFor="dob" className="block mb-1 font-medium">Date of Birth</label>
          <input
            type="date"
            id="dob"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            className={`border rounded-lg w-full p-2 ${errors.dob ? "border-red-500" : "border-gray-300"}`}
          />
          {errors.dob && <p className="text-red-500 text-sm mt-1">{errors.dob}</p>}
        </div>

        <button
          type="submit"
          className="bg-[#FFC34F] text-black rounded-lg py-2 hover:bg-black hover:text-white transition duration-200"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save Profile"}
        </button>
      </form>
    </div>
  );
};

export default ProfileForm;
