import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Blogs from './pages/Blogs/Blogs';
import LandingPage from './pages/Landing Page/LandingPage';
import FooterBlack from './components/Footer';
import Header from './components/Header';
import ExploreDestination from './pages/Explore Destinations/ExploreDestination';
import ItineraryPlanner from './pages/Itinerary Planner/ItineraryPlanner';
import "@buildo/bento-design-system/index.css"; // Importing the Bento Design System CSS
import "@buildo/bento-design-system/defaultTheme.css";
import { defaultMessages } from "@buildo/bento-design-system/defaultMessages/en";
import { BentoProvider } from "@buildo/bento-design-system";
import CityExplorer from './pages/CityExplorer/CityExplorer';
import PasswordPage from './pages/Password Page/PasswordPage'; 

function App() {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('authenticated');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/password');
    }
  }, [navigate, isAuthenticated]);

  return (
    <BentoProvider defaultMessages={defaultMessages}>
      <>
        {!isAuthenticated ? (
          <Routes>
            <Route path="/password" element={<PasswordPage />} />
          </Routes>
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/explore-destination" element={<ExploreDestination />} />
              <Route path="/ai-travel-itinerary" element={<ItineraryPlanner />} />
              <Route path="city-explorer" element={<CityExplorer />} />
              <Route path="/blogs" element={<Blogs />} />
            </Routes>
            <FooterBlack />
          </>
        )}
      </>
    </BentoProvider>
  );
}

export default App;
