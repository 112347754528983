import { useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    // useNavigate must be inside the functional component
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = formData;

        try {
            const { data: { user, session }, error } = await supabase.auth.signInWithPassword({
                email,
                password,
            });

            if (error) throw error;

            alert('Login successful!');
            console.log('User logged in:', user);
            console.log('Session token:', session.access_token); 

            // Redirect to home page
            navigate('/'); // Redirect after successful login

        } catch (error) {
            alert(error.message); // Handle login errors
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-white">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-2xl">
                <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFC34F]"
                            placeholder="Enter your email"
                        />
                    </div>

                    <div className="mb-6">
                        <label htmlFor="password" className="block text-gray-700 font-medium mb-2">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FFC34F]"
                            placeholder="Enter your password"
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-[#FFC34F] text-black font-medium py-2 px-4 rounded-md hover:bg-[#FFC34F] transition-colors"
                    >
                        Login
                    </button>

                    <p className="text-center mt-4">
                        Don't have an account?{' '}
                        <a href="/sign-up" className="text-[#FFC34F] hover:underline">
                            Sign Up
                        </a>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;
