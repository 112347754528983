import React, { useState } from 'react';
import { ReactComponent as Clock } from "../../assets/Clock1.svg";
import { ReactComponent as LightHouse } from "../../assets/Lighthouse.svg";
import { ReactComponent as Ticket } from "../../assets/Ticket.svg";

const CityPlaceCard = ({ imageComponent, placename, ticket, travelTime, placeType, onSelection }) => {
    const [isSelected, setIsSelected] = useState(false);

    const handleCheckboxChange = () => {
        setIsSelected(!isSelected);
        onSelection({ imageComponent, placename, ticket, travelTime, placeType }); // Pass card details to parent component
    };

    return (
        <div className='w-[19.125rem] h-max shadow-lg rounded-xl'>
            <div className='w-[17.5rem] h-[11.563rem] p-3'>
                {imageComponent}
            </div>
            <div>
                <p className='font-plus-jakarta-sans font-semibold text-2xl px-2 mt-4'>{placename}</p>
            </div>
            <div className='flex p-2 gap-2'>
                <Clock className='h-5 w-5' />
                <p className='font-poppins font-medium text-sm text-[#1C1C1C]'>Duration:</p>
                <p className='font-poppins font-semibold text-sm text-[#1C1C1C]'>{travelTime}</p>
            </div>
            <div className='flex justify-between p-3'>
                <div className='w-max text-sm h-full p-2 bg-[#F3EAD7] flex gap-2 rounded-lg'>
                    <LightHouse className='h-5' />
                    <div>{placeType}</div>
                </div>
                <div className='p-2 bg-[#F3EAD7] w-max h-full flex rounded-lg gap-4'>
                    <Ticket className='' />
                    <p className='font-poppins font-semibold text-[#1C1C1C] text-xs'>{ticket} Tickets</p>
                </div>
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox h-6 w-6 bg-black text-black rounded-full focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        checked={isSelected}
                        onChange={handleCheckboxChange}
                    />
                    
                </label>
            </div>
        </div>
    );
};

export default CityPlaceCard;
