import React from 'react'
import { ReactComponent as Star } from "../../assets/1star.svg";

const Score = ({starRating ,activitySymbol , activity }) => {
  return (
    <div className='w-max p-4 h-max bg-[#F7F4EF] cursor-default rounded-[20px] font-dm-sans font-medium'>
      <div className='flex justify-center gap-x-1'>
        <Star className='w-[19.6px] h-[19.6px]'/>
        <p className='text-[22.4px]'> {starRating}</p>
      </div>
      <div className='flex justify-center gap-x-3'>
        <div className='w-6 h-6'>{activitySymbol}</div>
        <div>
            <p className='text-base'>{activity}</p>
        </div>
      </div>
    </div>
  )
}

export default Score
