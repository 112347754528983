import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const PasswordPage = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const storedPassword = process.env.REACT_APP_PROTECT;
  
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === storedPassword) { 
      localStorage.setItem('authenticated', 'true');
      navigate('/');
    } else {
      alert('Incorrect Password');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-md text-center">
        <h1 className="mb-6 text-2xl text-gray-700 font-bold">Password Protection</h1>
        <form onSubmit={handlePasswordSubmit}>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Enter Password" 
            className="w-full p-3 mb-4 border border-gray-300 rounded-lg text-lg"
          />
          <button 
            type="submit" 
            className="w-full p-3 bg-green-500 text-white rounded-lg text-lg transition-colors duration-300 hover:bg-green-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPage;
