import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Component Imports
import PackingGuides from '../../components/ItineraryPlanner/PackingGuides';
import Score from '../../components/CityExplorer/Score';
import PlanMyItinerary from '../../components/CityExplorer/Plan_my_itinery';
import CityPlaceCard from '../../components/CityExplorer/CityPlaceCard';
import FestivalsAndEvents from '../../components/CityExplorer/FestivalsAndEvents';
import AdvisoryButton from '../../components/CityExplorer/AdvisoryButton';
import Restriction from '../../components/CityExplorer/Restriction';
import OtherLocation from '../../components/CityExplorer/OtherPlaces';
import CityName from '../../components/CityExplorer/CityName';
import About from '../../components/CityExplorer/About';
import Tags from '../../components/CityExplorer/Tags';
import SelectedCardModal from '../../components/CityExplorer/SelectetedCardModal';

//Image as component import
import { ReactComponent as MapTrifold } from "../../assets/MapTrifold.svg";
import { ReactComponent as BeachTowel } from "../../assets/beachTable.svg";
import { ReactComponent as BeachBag } from "../../assets/beachBag.svg";
import { ReactComponent as WaterBottle } from "../../assets/waterBottle.svg";
import { ReactComponent as Charger } from "../../assets/charger.svg";
import { ReactComponent as Camera } from "../../assets/camera.svg";
import { ReactComponent as PowerBank } from "../../assets/powerBank.svg";
import { ReactComponent as Cash } from "../../assets/card.svg";
import { ReactComponent as TravelGuide } from "../../assets/travelGuide.svg";
import { ReactComponent as FirstAid } from "../../assets/firstAid.svg";
import { ReactComponent as ExperienceSymbol } from "../../assets/Experience.svg";
import { ReactComponent as HeritageSymbol } from "../../assets/Heritage.svg";
import { ReactComponent as CityImage } from "../../assets/CityImage.svg";
import { ReactComponent as CityMap } from "../../assets/CityMap.svg";
import { ReactComponent as CityPlace } from "../../assets/cityPlace.svg";
import { ReactComponent as CityImageBackground } from "../../assets/cityExplorerBackground.svg";
import { ReactComponent as NightlifeSymbol } from "../../assets/NightlifeSymbol.svg";
import { ReactComponent as BeachSymbol } from "../../assets/BeachSymbol.svg";
import { ReactComponent as ActivitiesSymbol } from "../../assets/ActivitiesSymbol.svg";
import { ReactComponent as FoodSymbol } from "../../assets/FoodSymbol.svg";
import { ReactComponent as ReligiousSymbol } from "../../assets/ReligiousSymbol.svg";
import { ReactComponent as SpiritualSymbol } from "../../assets/SpiritualSymbol.svg";
import { ReactComponent as CulinarySymbol } from "../../assets/CulinarySymbol.svg";
import { ReactComponent as ShoppingSymbol } from "../../assets/ShoppingSymbol.svg";
import { ReactComponent as AdventureSymbol } from "../../assets/AdventureSymbol.svg";


const CityExplorer = () => {
    const [selectedBox, setSelectedBox] = useState('');
    const [selectedCards, setSelectedCards] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleBoxClick = (box) => {
        setSelectedBox(box);
    };

    const handleCardSelection = (card) => {
        // Check if the card is already selected
        const isSelected = selectedCards.some(selectedCard => selectedCard.placename === card.placename);

        if (isSelected) {
            // If already selected, remove it from the selected list
            const updatedSelectedCards = selectedCards.filter(selectedCard => selectedCard.placename !== card.placename);
            setSelectedCards(updatedSelectedCards);
        } else {
            // If not selected, add it to the selected list
            setSelectedCards([...selectedCards, card]);
        }
    };

    const handleSelectedCardsClick = () => {
        // Logic to display the list of selected place cards
        setIsModalVisible(true);
        console.log("Selected cards:", selectedCards);
    };

    return (
        <>
            <div className='flex justify-start ml-[15%] text-base font-dm-sans font-medium gap-x-9 mt-11 cursor-pointer'>
                <Link to='/' className='hover:text-[#EA9E2A]'>Home</Link>
                <p> &gt; </p>
                <Link to='/explore-destination' className='hover:text-[#EA9E2A]'>Explore Destination</Link>
                <p> &gt; </p>
                <p className='hover:text-[#EA9E2A]'>Goa</p>
            </div>

            <div className='flex justify-center  mt-2'>
                <CityImageBackground />
            </div>
            <div className='flex justify-center gap-x-9 p-11 px-16'>
                
                <div className='flex justify-normal gap-x-5 py-7 font-poppins font-semibold -ml-[37%]'>
                    <p className='w-max h-max rounded-[48px] p-4 text-black hover:text-white bg-[#A2ACD14D] hover:bg-black'>About</p>
                    <p className='w-max p-4 text-black h-max rounded-[48px] hover:text-white bg-[#A2ACD14D] hover:bg-black'>Things to do</p>
                    <p className='w-max p-4 text-black h-max rounded-[48px] hover:text-white bg-[#A2ACD14D] hover:bg-black'>Packing guides</p>
                    <p className='w-max p-4 text-black h-max rounded-[48px] hover:text-white bg-[#A2ACD14D] hover:bg-black'>Restrictions</p>
                </div>
                
            </div>
            <div className='py-4 mr-[9%] fixed left-[79%] top-[60%]'>
                <PlanMyItinerary
                        imageComponent={<MapTrifold />}
                        text="Plan my itinerary"
                        backgroundColor="white"
                        description="Stays, Transits, Sightseeing"
                        isSelected={selectedBox === 'Plan my itinerary'}
                        onClick={() => handleBoxClick('Plan my itinerary')}
                    />
                    
                </div>
                <div className='py-4 mr-[9%] fixed left- top-[60%]'>
                    <div className='bg-[#FFD27A] cursor-pointer gap-x-5 p-5 w-[17.75rem] h-[72px] rounded-xl flex justify-center font-plus-jakarta-sans font-semibold text-2xl'
                    onClick={handleSelectedCardsClick}>
                        <p> {selectedCards.length}</p>
                        <p>Selected</p>
                        <p> &gt; </p>

                    </div>
                </div>
            <div className='flex justify-center  py-4 px-'>
                    <p className='font-plus-jakarta-sans font-semibold text-2xl -ml-[8%]'>Scoring</p>
                    <p className='font-dm-sans font-normal mr-[45%] p-1 px-2'>As per your preferences</p>
                    <p className='font-dm-sans font-normal py-1 -mr-[13%]'>Change preferences</p>
                    
            </div>
            <div className='flex justify-center gap-x-4 py-4'>
            
                <Score 
                    starRating='5'
                    activitySymbol={<ActivitiesSymbol />}
                    activity='Activities'
                />
                <Score 
                    starRating='5'
                    activitySymbol={<ReligiousSymbol />}
                    activity='Relgious'
                />
                <Score 
                    starRating='5'
                    activitySymbol={<SpiritualSymbol />}
                    activity='Spirituality'
                />
                <Score 
                    starRating='5'
                    activitySymbol={<ShoppingSymbol />}
                    activity='Shopping'
                />
                <Score 
                    starRating='5'
                    activitySymbol={<CulinarySymbol />}
                    activity='Culinary'
                />
                <Score 
                    starRating='5'
                    activitySymbol={<AdventureSymbol />}
                    activity='Adventure'
                /> 
                <Score 
                    starRating='5'
                    activitySymbol={<AdventureSymbol />}
                    activity='Adventure'
                />
            </div>
            
            
            <div className='flex justify-center gap-x-5 mt-7'>
                <About 
                    descriprition='Goa is a state located in western India, known for its beautiful beaches, vibrant nightlife, and Portuguese colonial architecture. It is a popular tourist destination.'
                /> 
                
                <div className='flex flex-col'>
                    <div className='flex gap-x-6'>
                        <CityImage 
                            className='w-[21.688rem] h-[15.375rem] rounded-[20px]'
                        />
                        <CityMap />
                    </div>
                    <div className='mt-5'>
                        
                        <FestivalsAndEvents />
                    </div> 
                </div>
            </div>
            <div className='font-plus-jakarta-sans font-semibold text-[32px] flex ml-[11%] py-9'>
                    <p>Things to do</p>
            </div>
            <div className='flex gap-x-16'>
                <div className='flex flex-col ml-[11%] gap-y-2'>
                    <div>
                        <p className='text-lg font-dm-sans font-medium'>Filters   </p>
                    </div>
                    <div className='w-[10.563rem] h-[23.375rem] flex flex-col gap-y-4 border-[#9AA8BC4D] border-[1px] bg-white   rounded-xl p-3  font-dm-sans font-medium text-lg  justify-start items-start  cursor-pointer '>
                        <p className='hover:text-[#FFB543]'>All</p>
                        <p className='hover:text-[#FFB543] flex gap-x-3'><BeachSymbol/> Beach</p>
                        <p className='hover:text-[#FFB543] flex gap-x-3'><AdventureSymbol />Adventure</p>
                        <p className='hover:text-[#FFB543] flex gap-x-3'><ExperienceSymbol/>Experiences</p>
                        <p className='hover:text-[#FFB543] flex gap-x-3'><FoodSymbol />Food</p>
                        <p className='hover:text-[#FFB543] flex gap-x-3'><ShoppingSymbol />Shopping</p>
                        <p className='hover:text-[#FFB543] flex gap-x-3'><HeritageSymbol />Heritage</p>
                        
                    </div>
                    <div>
                        <p className='text-lg font-dm-sans font-medium mt-2'>Duration  </p>
                    </div>
                    <div className='w-[10.563rem] h-[9.5rem] flex flex-col gap-y-4 border-[#9AA8BC4D] border-[1px] bg-white   rounded-xl p-3  font-dm-sans font-medium text-lg  justify-start items-start  cursor-pointer '>
                        <p className='hover:text-[#FFB543]'>30 mins</p>
                        <p className='hover:text-[#FFB543] '>1-2 hours </p>
                        <p className='hover:text-[#FFB543]'>2-3 hours</p>
                        
                        
                    </div>
                    
            </div>
            <div>
                        <div className='flex gap-x-8'>
                            <Tags tag='Most Loved'/>
                            <Tags tag='North Goa' />
                            <Tags tag='South Goa' />
                        </div>
                        <div className='grid grid-cols-3 h-[46.5rem] mt-3 w-max gap-x-12 gap-y-7 overflow-x-hidden overflow-y-scroll'>
                        
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Agra Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Allahabad Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className=' w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        <CityPlaceCard
                            imageComponent={<CityPlace className='w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        
                        <CityPlaceCard
                            imageComponent={<CityPlace className='w-[17.5rem] h-[11.563rem]' />}
                            placename='Aguda Fort'
                            travelTime='1 to 2 hrs'
                            placeType='Monument'
                            ticket='No'
                            onSelection = {handleCardSelection}
                        />
                        </div>
                    </div>
            </div>
        

                <div className='font-plus-jakarta-sans font-semibold text-[32px] flex ml-[11%] py-9'>
                    <p>Packing guides</p>
            </div>
            <div className='flex justify-center gap-14 mt-9'>
          <PackingGuides
            heading='Beach'
            img1={<BeachTowel />}
            img2={<BeachBag />}
            img3={<WaterBottle />}
            name1='Towel'
            name2='Beach Bag'
            name3='Water Bottle'
            line1='Towel: For lounging on the sand and drying off'
            line2='Beach Bag: To carry your essentials to the beach'
            line3='Water Bottle: Stay hydrated under the sun'
          />
          <PackingGuides
            heading='Electronics'
            img1={<Charger />}
            img2={<Camera />}
            img3={<PowerBank />}
            name1='Charger'
            name2='Camera'
            name3='Power Bank'
            line1='Phone and Charger: Keep in a waterproof case or pouch'
            line2='Camera or GoPro: Capture those memorable moments'
            line3='Portable Power Bank: For charging your devices on the go'
          />
          <PackingGuides
            heading='Miscellaneous'
            img1={<Cash />}
            img2={<TravelGuide />}
            img3={<FirstAid />}
            name1='Cash, Cards'
            name2='Travel Guide'
            name3='First Aid'
            line1='Reusable Water Bottle'
            line2='Cash and Cards: Have some cash for small transactions as not all places accept cards'
            line3='Travel Guide/Map: If you are planning on exploring beyond the beaches'
          />
        </div>
                    <div className='flex justify-between mt-16'>
                        <p className='font-poppins font-medium text-[32px] ml-[11%]'>Restrictions</p>
                        <div className='flex gap-4 mr-28'>
                             <AdvisoryButton buttonText='Travel Advisory' />
                            <AdvisoryButton buttonText='News' /> 
                        </div>
                    </div>
                    <div className='flex justify-center gap-x-9 mt-9'>
                         <Restriction 
                            restrictionHeading='Avoid getting in the taxi if your driver is intoxicated'
                            restrictionDetail='Goa is generally safe but it is not recommended to stray off the beaten track or unlit areas. As there is a big drug scene in Goa, if you need to get a taxi at night, keep in mind that there could be a risk that the taxi driver is high or drunk, so avoid getting in the car.'
                        />
                        <Restriction 
                            restrictionHeading='Is Goa safe for girls at night?'
                            restrictionDetail='Goa is generally considered a safer destination for solo female travelers compared to some other parts of the country. However, its important to take precautions if you are out alone at night. Stick to more populated areas and take well-lit, main roads.'
                        />
                        <Restriction 
                            restrictionHeading='Is it safe to rent a scooter in Goa?'
                            restrictionDetail='Depending on where you are in Goa, its generally pretty chilled out and its not like trying to drive a scooter in Delhi. Just make sure you donot get ripped off. The price should vary somewhere between 150 Rs - 300 Rs per day, depending on the amount of time you are hiring it for.'
                        /> 
                        

                    </div>
                    <div className='flex font-plus-jakarta-sans font-semibold text-[32px] mt-16 justify-center'>
                    <p>Other Locations</p>
                    
                </div>
                <div className='flex justify-center gap-x-9 mt-9'>
                    <OtherLocation 
                        imageComponent={<CityPlace className='p-4'/>}
                        placename='Thailand'
                    />
                    <OtherLocation 
                        imageComponent={<CityPlace className='p-4'/>}
                        placename='Thailand'
                    />
                    <OtherLocation 
                        imageComponent={<CityPlace className='p-4'/>}
                        placename='Thailand'
                    />
                    
                </div>
                <div className='h-7'></div>
                
                {isModalVisible && (
                <SelectedCardModal
                    selectedCards={selectedCards}
                    onClose={() => setIsModalVisible(false)}
                />
            )}


        </>
    );
}

export default CityExplorer;
