import React from 'react';

const imageUrl = "https://www.sikkimtourismindia.com/uploads/darjeelingtour2.jpg";

const Blogs = () => {
  return (
    <div className="max-w-4xl mx-auto p-5"> 
      <h1 className="text-center text-4xl font-bold mb-4 text-green-600">DARJEELING</h1>
      <div className='text-center'>
        <p className="text-justify leading-7 text-lg text-gray-600 my-5">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate eu scelerisque felis imperdiet proin fermentum. Accumsan sit amet nulla facilisi morbi tempus iaculis urna id. Eu non diam phasellus vestibulum lorem sed. Ac tortor dignissim convallis aenean et tortor at risus. Sit amet cursus sit amet dictum sit. Nulla at volutpat diam ut venenatis tellus in metus vulputate. Aliquam purus sit amet luctus venenatis lectus. Velit ut tortor pretium viverra. Ac odio tempor orci dapibus ultrices in iaculis nunc. Id volutpat lacus laoreet non curabitur gravida. Sit amet volutpat consequat mauris nunc congue nisi. Risus feugiat in ante metus dictum at tempor.
        </p>
        <img src={imageUrl} alt="Darjeeling" className="max-w-full h-auto block mx-auto my-5"/>
      </div>
    </div>
  );
}

export default Blogs;
