import React from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as TravelMaglQLogo } from "../assets/travelMaglQ-logo-footer.svg";
import { ReactComponent as User } from "../assets/user.svg";

const FooterBlack = () => {
  return (
    <>
    <footer className='absolute  w-full bg-[#1C1C1C] lg:h-[30.5rem] h-[40.75rem]'>
      <div className='relative'>
        <div className='flex mt-20 lg:mt-28  justify-center'>
          <TravelMaglQLogo className='h-12 lg:h-[5.063rem]'/>
        </div>
        <div>
          <p className='font-plus-jakarta-sans text-[#ffff] text-center font-semibold text-[20px] lg:text-[32px] relative mt-2'>
            Plan your travel journey with ease!
          </p>
        </div>
        <div className='flex mt-9 justify-center'>
        <div className='relative flex justify-start'>
          <User className='absolute left-4 top-1/2 transform -translate-y-1 text-[#7D7A91]' />
          <input
            type='text'
            placeholder='Enter your email'
            className='w-[16.5rem] lg:w-[22.5rem] h-[3.25rem] rounded-3xl bg-transparent border-[1px] border-[#7D7A91] mt-4  lg:ml-[0%] px-16 font-plus-dm-sans font-normal text-md text-[#B3ADAD] '
          />
          </div>
          <button className="hidden lg:block bg-[#FFC34F] text-[#1C1C1C] font-dm-sans font-semibold py-2 px-4 rounded-3xl w-[7.313rem] h-[3.25rem] hover:bg-[#FFF3DC] hover:text-[#1C1C1C] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ml-3">
            Try Now
          </button>
        </div>
        <nav className="flex flex-col  lg:flex-row lg:gap-20 lg:mt-12 p-4 items-center justify-center">
          <Link
            to="/features"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            Features
          </Link>
          <Link
            to="/plansandpricing"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            Plans & Pricing
          </Link>
          <Link
            to="/newsandblogs"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            News & Blogs
          </Link>
          <Link
            to="/careers"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            Careers
          </Link>
          <Link
            to="/aboutus"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            About Us
          </Link>
          <Link
            to="/Terms"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            Terms
          </Link>
          <Link
            to="/privacy"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            Privacy
          </Link>
          <Link
            to="/cookie"
            className="text-[#B3ADAD] font-poppins font-normal no-underline text-md hover:text-[#FFC34F] flex items-center h-8 py-2"
          >
            Cookies
          </Link>
        </nav>
      </div>
      <div className=''>
      <div className='  h-3 mt-12 lg:mt-1 w-full bg-[#FFC34F] '></div>
      </div>
    </footer>
    
    </>
  );
}

export default FooterBlack;
