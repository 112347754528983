import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../utils/supabaseClient.js"; // Adjust this path to your supabaseClient.js
import logo from "../assets/travelMagIQLogo.svg";
import { ReactComponent as HeaderListView } from "../assets/HeaderListView.svg";
import { ReactComponent as UserProfileIcon } from "../assets/userProfile.svg";
import { ReactComponent as SearchIcon } from "../assets/searchGrey.svg";
import GlobalSearchDialogueBox from "./GlobalSearch/GlobalSearchDialogueBox";

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchBoxRef = useRef(null);
  const [session, setSession] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setIsSearchOpen(false);
    }
    if (event.target.closest(".user-profile-dropdown") === null) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen]);

  useEffect(() => {
    const session = supabase.auth.getSession();
    setSession(session);

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
    });

    return () => {
      authListener?.unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error("Error signing out:", error.message);
    else console.log("Signed out successfully!");
  };

  const handleProfileClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const navigateToProfile = () => {
    navigate("/my-profile"); // Adjust this path as necessary
    setIsDropdownOpen(false);
  };

  return (
    <div className="flex">
      <header
        className="flex justify-between shadow-xl lg:items-center p-4 md:p-4 lg:p-6 lg:w-[92%] w-full rounded-b-3xl pl-10 md:pl-10 lg:pl-12 lg:translate-x-14 md:translate-x-1/16 lg:translate-x-1/8"
        style={{ backgroundColor: "#ffff" }}
      >
        <Link to="/">
          <img
            src={logo}
            alt="travelmagiQ logo"
            className="h-12 lg:h-16 translate-y-1 cursor-pointer"
          />
        </Link>
        <button className="lg:hidden">
          <HeaderListView />
        </button>

        <nav className="hidden lg:flex gap-8 translate-x-2">
          <div className="relative flex justify-start">
            <SearchIcon
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#7D7A91]"
              onClick={toggleSearch}
            />
            <input
              type="text"
              placeholder="City Wiki..."
              className={`search-input h-12 pl-12 rounded-3xl bg-transparent border-[1px] border-[#120e29] font-plus-dm-sans font-normal text-md text-[#220f0f] transition-all duration-300 ease-out ${
                isSearchOpen ? "w-[25rem] h-16" : "w-[12.573rem] h-12"
              }`}
              onClick={toggleSearch}
            />
          </div>
          <Link to="/explore-destination" className="text-[#1C1C1C] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center h-12 py-2">
            Explore Destinations
          </Link>
          <Link to="/ai-travel-itinerary" className="text-[#1C1C1C] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center h-12 py-2">
            AI Travel Itinerary
          </Link>
          <Link to="/blog" className="text-[#1C1C1C] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center h-12 py-2">
            Blog
          </Link>

          <div className="hidden lg:flex items-center gap-5 -translate-x-1">
            {session ? (
              <div className="relative user-profile-dropdown">
                <UserProfileIcon onClick={handleProfileClick} className="cursor-pointer" />
                {isDropdownOpen && (
                  <div className="absolute right-0 bg-white shadow-lg rounded-md mt-2">
                    <button
                      onClick={navigateToProfile}
                      className="block p-4 text-base text-black hover:bg-gray-100 w-[7rem] text-center"
                    >
                      My Profile
                    </button>
                    <button
                      onClick={handleSignOut}
                      className="block p-4 text-base text-black hover:bg-gray-100 w-[7rem] text-center"
                    >
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Link to="/log-in" className="text-[#1C1C1C] font-plus-jakarta-sans font-semibold no-underline text-lg hover:text-[#FFC34F] flex items-center">
                  Log In
                </Link>
                <Link to="/sign-up">
                  <button className="bg-yellow-400 text-[#1C1C1C] font-plus-jakarta-sans font-semibold py-2 px-4 rounded-3xl hover:bg-[#1C1C1C] hover:text-[#ffff] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                    Sign Up
                  </button>
                </Link>
              </>
            )}
          </div>
        </nav>
      </header>
      {isSearchOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-center items-start"
          onClick={toggleSearch} // Close when clicking outside the dialogue box
        >
          <div
            ref={searchBoxRef}
            className="relative z-50 bg-white"
            onClick={(e) => e.stopPropagation()} // Prevent click inside the box from closing it
          >
            <GlobalSearchDialogueBox toggleSearch={toggleSearch} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
