import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as RangeSelector } from "../../assets/rangeSelector.svg";
import Compass from "./Compass";

const TripType = ({style}) => {
  const [selectedWeather, setSelectedWeather] = useState("Sunny");
  const [selectedLocation, setSelectedLocation] = useState("Local");
  const [numberOfDays, setNumberOfDays] = useState("1 day");
  const [budget, setBudget] = useState(2000);
  const [sliderPosition, setSliderPosition] = useState(0);
  const [explorePressed, setExplorePressed] = useState(false);

  const sliderRef = useRef(null);

  const handleButtonClick = (weatherType) => {
    console.log(weatherType)
    setSelectedWeather(weatherType);
  };

  const handleLocationButtonClick = (locationType) => {
    console.log(locationType)
    setSelectedLocation(locationType);
  };

  const increaseDays = () => {
    const newNumberOfDays = parseInt(numberOfDays) + 1;
    setNumberOfDays(`${newNumberOfDays} day`);
  };

  const decreaseDays = () => {
    if (parseInt(numberOfDays) > 1) {
      const newNumberOfDays = parseInt(numberOfDays) - 1;
      setNumberOfDays(`${newNumberOfDays} day`);
    }
  };

  const handleBudgetChange = (e) => {
    const value = parseInt(e.target.value);
    setBudget(value); 
    setSliderPosition((value / 200000) * 100);
  };

  useEffect(() => {
    const handleAnimationFrame = () => {
      if (sliderRef.current) {
        const { max, value } = sliderRef.current;
        const percent = (value / max) * 100;
        setSliderPosition(percent);
      }
      requestAnimationFrame(handleAnimationFrame);
    };
    handleAnimationFrame();
  }, []);

  const buttonClass = (weatherType) => (
    `bg-white hover:bg-[#FFC34F] border-[1.4px] border-[#9AA8BC4D] 
    text-[#1C1C1C] font-plus-jakarta-sans font-semibold py-2 px-4 
    rounded-xl ${selectedWeather === weatherType ? 'bg-[#FFC34F]' : ''}`
);

  const locationButtonClass = (locationType) =>
    `bg-white border-[1.4px] border-[#9AA8BC4D] text-[#1C1C1C] font-plus-jakarta-sans font-semibold py-2 px-4 rounded-xl ${
      selectedLocation === locationType? "bg-[#FFC34F]" : ""
    } hover:bg-[#FFC34F]`;
  
  const handleExploreClick = () => {
    setExplorePressed(true);
  };

  return (
    <div style={style}>
      {!explorePressed ? (
        <div className="rounded-xl border-[1.4px] border-[#9AA8BC4D] w-[36rem] ml-[6rem] p-4 mb-9">
          <div>
            <p className="font-plus-jakarta-sans font-semibold text-xl">
              Select your preferences
            </p>
          </div>
          <div>
            <p className='font-plus-jakarta-sans font-medium text-md'>
              Weather
            </p>
          </div>
          <div className='flex gap-6'>
            <button
              className={buttonClass('Sunny')}
              onClick={() => handleButtonClick('Sunny')}
            >
              Sunny
            </button>
            <button
              className={buttonClass('Snowy')}
              onClick={() => handleButtonClick('Snowy')}
            >
              Snowy
            </button>
            <button
              
              onClick={() => handleButtonClick('Rainy')}
              className={buttonClass('Rainy')}
            >
              Rainy
            </button>
            <button
              className={buttonClass('Cold')}
              onClick={() => handleButtonClick('Cold')}
            >
              Cold
            </button>
          </div>
          <div>
            <p className="font-plus-jakarta-sans font-medium text-md mt-2">
              Locations
            </p>
          </div>
          <div className="flex gap-6 mt-2">
            <button
              className={locationButtonClass("Local")}
              onClick={() => handleLocationButtonClick("Local")}
            >
              Local
            </button>
            <button
              className={locationButtonClass("International")}
              onClick={() => handleLocationButtonClick("International")}
            >
              International
            </button>
          </div>
          <div>
            <p className="font-plus-jakarta-sans font-medium text-md mt-2">
              Duration
            </p>
            <div className="flex items-center mt-1">
              <button className="text-lg px-2" onClick={decreaseDays}>
                -
              </button>
              <input
                type="text"
                readOnly
                className="w-20 border bg-[#F3EAD7] border-gray-300 font-plus-jakarta-sans font-medium rounded-md px-2 py-1 text-lg text-center"
                value={numberOfDays}
              />
              <button className="text-lg px-2" onClick={increaseDays}>
                +
              </button>
            </div>
          </div>
          <div className="flex items-center mt-1 relative">
            <span className="font-plus-jakarta-sans font-medium text-lg mr-2">
              ₹2K
            </span>
            <div className="relative w-full">
              <input
                ref={sliderRef}
                type="range"
                min="2000"
                max="200000"
                value={budget}
                onChange={handleBudgetChange}
                className="w-full h-3 appearance-none rounded-md bg-gray-300 focus:outline-none z-10"
                style={{ MozAppearance: "none", WebkitAppearance: "none" }}
              />
              <div
                className="absolute top-0 left-0"
                style={{
                  transform: `translateX(${sliderPosition}%)`,
                }}
              >
                <RangeSelector />
              </div>
              <div
                className="absolute translate-y-1 top-0 left-0 bg-black h-3"
                style={{ width: `${sliderPosition}%` }}
              ></div>
            </div>
            <span className="font-plus-jakarta-sans font-medium text-lg ml-2">
              ₹2L
            </span>
          </div>
          <p className='font-plus-jakarta-sans font-medium ml-[50%] text-md mt-2'>
            ₹{budget}
          </p>
          <button 
            className="bg-[#1C1C1C] hover:text-[#1C1C1C] w-full text-lg text-white font-plus-jakarta-sans font-semibold py-2 px-4 rounded-xl hover:bg-[#FFD27A] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-left"
            onClick={handleExploreClick}
          >
            Explore
          </button>
          <p className="text-white hover:text-[#1C1C1C] font-plus-jakarta-sans font-semibold text-3xl -translate-y-10 translate-x-[90%]">&gt;</p>
        </div>
      ) : (
        <Compass />
      )}
    </div>
  );
};

export default TripType;
