import React, { useState, useEffect } from 'react';
import mapBackground from "../../assets/spinMap.svg";

// Components Import
import Box2 from '../../components/landingPage/Box2';
import TripType from '../../components/landingPage/TripType';
import EuropeEuropeNew from '../../components/landingPage/EuropeEuropeNew';
import ReviewCard from '../../components/landingPage/ReviewCard'
import PlanMyItinerary from '../../components/landingPage/PlanMyItinerary';

// Images Imported as Component
import { ReactComponent as ExplorePlacesCompass } from "../../assets/explorePlacesCompass.svg";
import { ReactComponent as MapTrifold } from "../../assets/MapTrifold.svg";
import { ReactComponent as Rio } from "../../assets/rio.svg";
import { ReactComponent as Venice } from "../../assets/venice.svg";
import { ReactComponent as Melbourne } from "../../assets/melbourne.svg";
import { ReactComponent as Beach } from "../../assets/beachBlack.svg";
import { ReactComponent as Line } from "../../assets/line.svg";
import { ReactComponent as Paris } from "../../assets/paris.svg";
import { ReactComponent as Romee } from "../../assets/rome2.svg";
import { ReactComponent as Venicee } from "../../assets/venice2.svg";
import { ReactComponent as WeOffer } from "../../assets/weOffer.svg";
import { ReactComponent as Service } from "../../assets/service.svg";
import { ReactComponent as Schedule } from "../../assets/schedule.svg";
import { ReactComponent as Discount } from "../../assets/discount.svg";
import { ReactComponent as Star } from "../../assets/5star.svg";
import { ReactComponent as Person } from "../../assets/Gauri.svg";




const LandingPage = () => {
  const [visibleComponent, setVisibleComponent] = useState('venice');
  const [selectedBox, setSelectedBox] = useState('');
  const [showSolutionMessage, setShowSolutionMessage] = useState(true);

  useEffect(() => {
    const components = ['venice', 'melbourne', 'rio'];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % components.length;
      setVisibleComponent(components[currentIndex]);
    }, 3000); // Change the component every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleBoxClick = (box) => {
    setSelectedBox(box);
    setShowSolutionMessage(false); // Hide the message when Explore places box is clicked
  };

  const reviews = [
    {
      description: '"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!"',
      userImage: <Person />,
      userName: 'Gauri Patel',
      userDescription: 'Home Maker',
      starImage: <Star />,
      userLocation: 'New Jersey, USA'
    },
    {
      description: '""A must-have for frequent travellers! The app features are incredibly helpful, and the community aspect is a nice touch. Thank you for making my travel more enjoyable"',
      userImage: <Person />,
      userName: 'Anmol Abrol',
      userDescription: 'Web Developer',
      starImage: <Star />,
      userLocation: 'Mumbai, India'
    },
    {
      description: '"As a first-time mom, this app has been a lifesaver. Easy to use, informative, and it feels like a friend guiding me through all  the kid friendly places in a tourist place. Thanks, TravelMagIQ“',
      userImage: <Person />,
      userName: 'Jessica Thompson',
      userDescription: 'Backpacker',
      starImage: <Star />,
      userLocation: 'Delhi, India'
    },
    {
      description: '"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!"',
      userImage: <Person />,
      userName: 'Gauri Patel',
      userDescription: 'Home Maker',
      starImage: <Star />,
      userLocation: 'New Jersey, USA'
    },
    {
      description: '"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!"',
      userImage: <Person />,
      userName: 'Gauri Patel',
      userDescription: 'Home Maker',
      starImage: <Star />,
      userLocation: 'New Jersey, USA'
    },
    {
      description: '"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!"',
      userImage: <Person />,
      userName: 'Gauri Patel',
      userDescription: 'Home Maker',
      starImage: <Star />,
      userLocation: 'New Jersey, USA'
    },
  ];

  const [currentReviewIndex , setCurrentReviewIndex] = useState(0);
  

  useEffect(() => {
    const reviewInterval = setInterval(() => {
      setCurrentReviewIndex((currentReviewIndex) => (currentReviewIndex + 1) % reviews.length);
    }, 10000);
    return () => clearInterval(reviewInterval);

  }, [reviews.length]);

  return (
    <>
      <div className="flex relative lg:ml-16 mt-0">
        <div
          className="absolute inset-0 mt-10 hidden lg:block" // Adjust top margin here
          style={{
            backgroundImage: `url('${mapBackground}')`,
            backgroundSize: "auto 75%",
            backgroundPosition: "right center",
            backgroundRepeat: "no-repeat",
            height: '40rem',
          }}
        />
        <div className="relative z-10">
        <div className='flex flex-col justify-center items-center lg:justify-start lg:items-start lg:ml-[17%] font-plus-jakarta-sans font-semibold text-[#1C1C1C] text-[32px] lg:text-5xl mt-12 lg:mt-20 gap-y-4'>
          <p className=' '>Plan your trip</p>
          <p className=''>in one place</p>
          </div>
          <div className='flex flex-col lg:flex-row  lg:gap-x-12 lg:ml-[9rem] justify-center items-center gap-y-5 lg:gap-y-0 mt-5 lg:mt-8'>
            <Box2 
              imageComponent={<ExplorePlacesCompass />}
              text="Explore Places"
              backgroundColor="white"
              description="Finding new destinations"
              isSelected={selectedBox === 'ExplorePlaces'}
              onClick={() => handleBoxClick('ExplorePlaces')}
            />
            <Box2 
              imageComponent={<MapTrifold />}
              text="Plan My Itinerary"
              backgroundColor="white"
              description="Stays, Transits, Sightseeing"
              isSelected={selectedBox === 'Plan my itinerary'}
              onClick={() => handleBoxClick('Plan my itinerary')}
            />
          </div>
          {showSolutionMessage && selectedBox !== 'Plan my itinerary' && (
            <div className='mt-5 lg:mt-24 text-center lg:text-left flex flex-col text-[20px] lg:text-2xl justify-center items-center lg:items-start lg:ml-[19%] font-plus-jakarta-sans font-semibold text-[#1A212B]' style={{ zIndex: '1' }}>
              <p className=''>
                We're your one stop solution to plan
              </p>
              <p className=''>
                the perfect holiday.
              </p>
            </div>
           
          )}
          
        </div>
        
        <div className='hidden lg:block'>
          {visibleComponent === 'venice' && (
            <div className='translate-y-[10rem] translate-x-[6rem]'>
              <Venice className='w-64'/>
            </div>
          )}
          {visibleComponent === 'melbourne' && (
            <div className='translate-y-[23rem] translate-x-[16rem]'>
              <Melbourne className='w-64'/>
            </div>
          )}
          {visibleComponent === 'rio' && (
            <div className='translate-y-[24rem] -translate-x-[3rem]'>
              <Rio className='w-64'/>
            </div>
          )}
        </div>
      </div>
      
      {selectedBox === 'ExplorePlaces' && <TripType style = {{position: 'absolute' , top: '30rem' , left: '20px'}} />}
      {selectedBox === 'Plan my itinerary' && <PlanMyItinerary style = {{position: 'absolute' , top: '30rem' , left: '10rem'}} />}
      <div style = {{marginTop: selectedBox === 'ExplorePlaces' || selectedBox === 'Plan my itinerary' ? '11rem' : '0'}} 
      className='absolute left-[45rem] overflow-hidden -translate-y-[4rem]'>
        <Line className='hidden lg:block '/>
      </div>

       {/* Below Plan My Itinerary - Static Content */}
      <div
          className=" w-[] inset-0 mt-10 block lg:hidden" // Adjust top margin here
          style={{
            backgroundImage: `url('${mapBackground}')`,
            backgroundSize: "contain ",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: '12.438rem',
          }}
        >
        <div className='block lg:hidden'>
          {visibleComponent === 'venice' && (
            <div className='absolute -translate-y-[1rem] translate-x-[7.5rem]'>
              <Venice className='w-36'/>
            </div>
          )}
          {visibleComponent === 'melbourne' && (
            <div className='absolute translate-y-[3rem] translate-x-[9.25rem]'>
              <Melbourne className='w-36'/>
            </div>
          )}
          {visibleComponent === 'rio' && (
            <div className='absolute translate-y-[3rem] translate-x-[4.5rem]'>
              <Rio className='w-36'/>
            </div>
          )}
        </div>
      </div>
    
      <div className='hidden lg:block' style = {{marginTop: selectedBox === 'ExplorePlaces' || selectedBox === 'Plan my itinerary' ? '30rem' : '0'}}>
              <p className='font-plus-jakarta-sans font-medium text-[32px] text-center mt-40 text-[#1C1C1C]'>
        How it works?
              </p>
              <p className='font-dm-sans font-normal text-lg text-center mt-1 text-[#1C1C1C]'>
        Smart intelligent travel planning from start till end
              </p>
      </div>
      <div className='hidden lg:block'>
        <div className='flex mt-16 justify-center gap-28'>
            <div className='h-[20rem] w-[31.25rem] bg-[#F3EAD7] rounded-[1.25rem]'></div>
            <div className=''>
              <p className='font-plus-jakarta-sans font-medium text-xl  mt-24 text-[#1C1C1C]'>Customized / contextualized Gen Ai</p>
              <p className='font-plus-jakarta-sans font-medium text-xl   text-[#1C1C1C]'>Travel Itineraries</p>
              <p className='font-dm-sans font-normal mt-4 text-lg   text-[#1C1C1C]'>Update / collaborate itineraries as you go. Efficient packing</p>
            </div>
        </div>
        <div className='flex mt-16 justify-center gap-28'>
            <div className='h-[20rem] w-[31.25rem] bg-[#00000010] rounded-[1.25rem]'></div>
            <div>
              <p className='font-plus-jakarta-sans font-medium text-xl  mt-24 text-[#1C1C1C]'>Customized / contextualized Gen Ai</p>
              <p className='font-plus-jakarta-sans font-medium text-xl   text-[#1C1C1C]'>Travel Itineraries</p>
              <p className='font-dm-sans font-normal mt-4 text-lg   text-[#1C1C1C]'>Update / collaborate itineraries as you go. Efficient packing</p>
            </div>
        </div>
        <div className='flex mt-16 justify-center gap-28'>
            <div className='h-[20rem] w-[31.25rem] bg-[#F3EAD7] rounded-[1.25rem]'></div>
            <div>
              <p className='font-plus-jakarta-sans font-medium text-xl  mt-24 text-[#1C1C1C]'>Update / collaborate itineraries as you</p>
              <p className='font-plus-jakarta-sans font-medium text-xl   text-[#1C1C1C]'>go. Efficient packing</p>
              <p className='font-dm-sans font-normal mt-4 text-lg   text-[#1C1C1C]'>Update / collaborate itineraries as you go. Efficient packing</p>
            </div>
        </div>
      </div>
      <div >
        <p className='font-plus-jakarta-sans font-medium text-[32px] text-center mt-5 lg:mt-40 text-[#1C1C1C]'>
          Explore Europe
        </p>

        <div className='flex flex-col lg:flex-row lg:gap-y-0 gap-y-16  lg:gap-7  lg:mt-6 justify-center items-center ml-[5%] lg:ml-0'>
          <EuropeEuropeNew 
            imageComponent={<Venicee className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]'/>}
            placename = "Venice"
            travelTime = "13hrs 40mins"
            tag= {<Beach className=""/>}
            placeType= "Water"
            travelMonth="Jan to July & Sep to Dec"
          />
          <EuropeEuropeNew 
            imageComponent={<Paris className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]'/>}
            placename = "Paris"
            travelTime = "13hrs 40mins"
            tag= {<Beach className=""/>}
            placeType= "Beach"
            travelMonth="Jan to July & Sep to Dec"
          />
          <EuropeEuropeNew 
            imageComponent={<Romee className='w-full lg:w-[25.375rem] h-[15rem] lg:h-[16rem]'/>}
            placename = "Rome"
            travelTime = "13hrs 40mins"
            tag= {<Beach className=""/>}
            placeType= "Beach"
            travelMonth="Jan to July & Sep to Dec"
          />
        </div>
      </div>

      <div>
      <div className='mt-16 lg:mt-40 flex flex-col lg:flex-row justify-center items-center gap-24'>
          <div className='text-center'>
            <p className='font-plus-jakarta-sans font-semibold text-[36px] lg:text-[44px] text-center  '>We Offer best services</p>
          <div className='hidden lg:block'>
          <p className='font-plus-jakarta-sans font-medium text-[#8C8B92] text-base lg:text-lg '>
            Lets us guide you to your dream destination with
          </p>
          <p className='font-plus-jakarta-sans font-medium text-[#8C8B92] text-base lg:text-lg '>
            expertise and care. Our commitment is to assist you 
          </p>
          <p className='font-plus-jakarta-sans font-medium text-[#8C8B92] text-base lg:text-lg '>
            discovering the perfect.
          </p>
          </div>
          <div className='w-full lg:hidden font-plus-jakarta-sans font-medium text-[#8C8B92] text-base text-start px-8 mt-4'>
            <p >Let us guide you to your dream destination with expertise and  care. Our commitment is to assist you discovering the perfect. </p>
          </div>
        <div className='w-full mt-4 lg:mt-0'>
          <div className='flex gap-2 lg:mt-20 lg:gap-6'>
            <Service className='ml-6 lg:ml-0'/>
            <div>
              <p className='font-plus-jakarta-sans font-semibold text-[#1C1C1C] text-lg lgtext-2xl'>
              We offer best services
            </p>
              <div>
            <p className='font-plus-jakarta-sans font-medium text-[#8C8B92] text-base lg:text-lg ml-2 mt-1'>
              Our commitment is to assist you discovering.
            </p>
              </div>
              
            </div>
          </div>
          
        <div className='flex mt- lg:mt-16 lg:gap-6'>
          <Schedule className='ml-6 lg:ml-0'/>
            <div>
              <p className='font-plus-jakarta-sans font-semibold text-[#1C1C1C] text-lg lg:text-2xl '>
              Schedule your trip
              </p>
              <div>
                <p className='font-plus-jakarta-sans font-medium text-[#8C8B92] text-base lg:text-lg mt-1'>
                Our commitment is to assist you discovering.
                </p>
              </div>
            </div>
        </div>
        <div className='flex lg:mt-16 lg:gap-6'>
            <Discount className='ml-6 lg:ml-0'/>
            <div>
              <p className='font-plus-jakarta-sans font-semibold text-[#1C1C1C] text-lg lg:text-2xl '>
              Get discounted coupon
              </p>
              <div>
                <p className='font-plus-jakarta-sans font-medium text-[#8C8B92] text-base lg:text-lg mt-1'>
                  Our commitment is to assist you discovering.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

          <div className='hidden lg:block h-[16.516rem] w-[16.516rem] mr-32'>
              <WeOffer />
          </div>
        </div>
      </div>
      
      <div className='w-full h-full bg-[#FFF3DC] mt-12 lg:mt-40 p-4 '>
        <p className='font-plus-jakarta-sans font-medium text-[32px] text-center  text-[#1C1C1C]'>
          What our customers say
        </p>
        <div className='hidden lg:flex p-7 gap-9 overflow-ellipsis'>
            <ReviewCard 
              description='"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!'
              userImage={<Person />}
              userName='Gauri Patel'
              userDescription='Home Maker'
              starImage={<Star />}
              userLocation='New Jersey, USA'
            />
            <ReviewCard 
              description='"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!'
              userImage={<Person />}
              userName='Gauri Patel'
              userDescription='Home Maker'
              starImage={<Star />}
              userLocation='New Jersey, USA'
            />
            <ReviewCard 
              description='"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!'
              userImage={<Person />}
              userName='Gauri Patel'
              userDescription='Home Maker'
              starImage={<Star />}
              userLocation='New Jersey, USA'
            />
        </div>
        <div className='hidden lg:flex p-7 gap-9'>
            <ReviewCard 
              description='"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!'
              userImage={<Person />}
              userName='Gauri Patel'
              userDescription='Home Maker'
              starImage={<Star />}
              userLocation='New Jersey, USA'
            />
            <ReviewCard 
              description='"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!'
              userImage={<Person />}
              userName='Gauri Patel'
              userDescription='Home Maker'
              starImage={<Star />}
              userLocation='New Jersey, USA'
            />
            <ReviewCard 
              description='"Absolutely love TravelMagIQ! They planned my trip in the most easiest way possible. Me and my friends never had to keep diaries for planning. Hassle-free tool for best travel memories!'
              userImage={<Person />}
              userName='Gauri Patel'
              userDescription='Home Maker'
              starImage={<Star />}
              userLocation='New Jersey, USA'
            />
        </div>
        <div className="lg:hidden">
        {reviews.map((review, index) => (
          <div
            key={index}
            className={`transition-opacity duration-500 ease-in-out ${index === currentReviewIndex ? 'opacity-100' : 'opacity-0'} ${
              index === currentReviewIndex ? 'block' : 'hidden'
            }`}
          >
            <ReviewCard {...review} />
          </div>
        ))}
      </div>
      </div>
    </>
  );
}

export default LandingPage;
